import React from "react";
import { Helmet } from "react-helmet";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import RightImageWithContentFeature from "../../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../../components/chat-channel/LeftImageWithContent";
import ArrowRightICon from "../../components/common/Icons";
import KeyResources from "../../components/common/KeyResources";
import FooterForm from "../../components/common/FooterForm";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { useMedia } from "use-media";

const cardOne = require("../../assets/images/homepage/group1.png");
const cardTwo = require("../../assets/images/homepage/group4.png");

export const blogImg = require("../../assets/img/integration/card_1.png");
export const whitepaperImg = require("../../assets/img/integration/card_2.png");
export const videoImg = require("../../assets/img/integration/card_3.png");

export const icon1 = require("../../assets/img/feature/features_main_sec_1_icon.png");
export const icon2 = require("../../assets/img/feature/features_main_sec_-1.png");
export const icon3 = require("../../assets/img/feature/marketplace.png");
export const icon4 = require("../../assets/img/feature/features_main_sec_7_icon.png");
export const icon5 = require("../../assets/img/feature/features_main_sec_5_icon.png");
export const icon6 = require("../../assets/img/feature/features_main_sec_6_icon.png");
export const onprem_icon = require("../../assets/img/feature/on_prem_icon.png");

const video = require("../../assets/video/Workativ_Feature.mp4");

const section_one = require("../../assets/img/feature/all_features/features_sec_1.png");
const section_two = require("../../assets/img/feature/all_features/features_sec_2.png");
const section_three = require("../../assets/img/feature/all_features/features_sec_3.png");
const section_four = require("../../assets/img/feature/all_features/features_sec_4.png");
const section_five = require("../../assets/img/feature/all_features/features_sec_6.png");
const section_six = require("../../assets/img/feature/all_features/features_sec_7.png");
const onprem = require("../../assets/img/feature/all_features/features_sec_5.png");

const featureContent = [
  {
    icon: icon1,
    title: "Chatbot Builder",
    header: "Simple and easy to use chatbot builder canvas",
    content:
      "Build awesome dialogs and deliver engaging end-user experiences using workativ assistant’s chatbot builder and dialog management functionality. Be the conversational ai champion for your team and unlock your creative dialog building skills for chatbot. No coding.",
    link: "/conversational-ai-platform/chatbot-builder",
    image: section_one,
    alt: "chatbot builder canvas",
  },
  {
    icon: icon2,
    title: "Chatbot Automation",
    header: "Superpower your chatbot with in-built workflow automation",
    content:
      "Automate IT and HR workflows from the chatbot using our no-code workflow automation builder in workativ assistant at no additional cost. Integrate chatbot with different applications in a click and provide instant resolution to end-user issues or service request.",
    link: "/conversational-ai-platform/chatbot-automation",
    image: section_two,
    alt: "chatbot with in-built workflow automation",
  },
  {
    icon: icon3,
    title: "Workflow Marketplace",
    header: "Workflow marketplace for chatbot",
    content:
      "Connect chatbot with apps and workflows in seconds. Access our workflow marketplace to choose from over 50+ apps and 400+ pre-built workflows at no additional costs. Easily connect, download, and activate in few clicks. No coding.",
    link: "/conversational-ai-platform/marketplace",
    image: section_three,
    alt: "Workflow marketplace for chatbot",
  },

  {
    icon: icon4,
    title: "chatbot channel",
    header: "Chatbot on Teams and Slack",
    content:
      "Turn your favorite collaboration app into modern employee self-service by deploying chatbot on your Teams or Slack in few clicks. Customize the end-user experience by applying your company branding, logo etc.",
    link: "/conversational-ai-platform/chatbot-channels",
    image: section_four,
    alt: "Chatbot on Teams and Slack",
  },
  {
    icon: onprem_icon,
    title: "On-prem App Connect",
    header: "On-prem app secure connector",
    content:
      "Use workativ’s secure connector to connect chatbot with your on-prem apps behind your corporate firewall to execute workflows without a hitch. No need to open ports.",
    link: "/conversational-ai-platform/on-prem-app-connector",
    image: onprem,
    alt: "On-prem app secure connector",
  },
  {
    icon: icon5,
    title: "TRAINING",
    header: "Great experience comes from small improvements over time",
    content:
      "Refine end user experience by improving chatbot interactions using workativ’s training module. Very helpful in fine-tuning your chatbot.  ",
    link: "/conversational-ai-platform/logs-analytics-training",
    image: section_five,
    alt: "chatbot training",
  },
  {
    icon: icon6,
    title: "Analytics & Logs",
    header: "Analytics & logs - deep dive on chatbot interactions",
    content:
      "Refine chatbot conversations by analyzing end-user and chatbot interactions using workativ’s chatbot analytics and logs capability. Developing improvements for the chatbot is easy as a breeze when you know what went under the hood.",
    link: "/conversational-ai-platform/logs-analytics-training",
    image: section_six,
    alt: "chatbot analytics & logs",
  },
];

const resoucesData = [
  {
    image: blogImg,
    header: "How Automation is Changing the Face of Business",
    url: "https://blog.workativ.com/",
    link: "Learn more",
    alt: "blog",
  },
  {
    image: whitepaperImg,
    header: "Read about why conversational AI is becoming essential to…",
    url: "/conversational-ai-platform/chatbot-whitepaper",
    link: "Read Blog",
    alt: "blog",
  },
  {
    image: videoImg,
    header: "How to Integration app step by step guide…",
    url: "https://www.youtube.com/channel/UCDNRPFuv8tI7XDm117XV9eg",
    link: "Get the Guide",
    alt: "Get the Guide",
  },
];
const ldSchema = {
  "@context": "http://schema.org/",

  "@id":
    "https://workativ.com/static/Workativ_Feature-0b223bdf085e4bbbcde84ab5a71053c2.mp4",

  "@type": "VideoObject",
  duration: "PT6S",
  name: "Workativ Assistant",

  thumbnailUrl:
    "https://workativ.com/static/features_main_sec_1-16e28517d883fea5f824756397120e71.png",

  embedUrl:
    "https://workativ.com/static/Workativ_Feature-0b223bdf085e4bbbcde84ab5a71053c2.mp4",

  uploadDate: "2020-01-09",

  description:
    "Conversational AI Chatbot with Workflow Automation for IT Support and HR",
};
export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Conversational AI Features | Workativ Assistant Chatbot Features"
        description="Explore workativ assistants' conversational ai features such as NLP,  Chabot builder, chatbot automation, slack and teams integrations, and more."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogTitle="Conversational AI Features | Workativ Assistant Chatbot Features"
        ogDescription="Explore workativ assistants' conversational ai features such as NLP,  Chabot builder, chatbot automation, slack and teams integrations, and more."
        ogImage={section_one}
        schema={ldSchema}
      />
      <div className="all_features_wrapper">
        <Container>
          <Layout backgroundColor={"bg_slider_home pad_0"} logoFor="ASSISTANT">
            <section className="main-slider bg_slider_home features_header_home">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 align_center_content">
                    <div className="main-slider-left ibm_inte_header">
                      <h1>Transform your workplace support in minutes</h1>
                      <p className="pad_right_20">
                        Finally, a single platform that delivers both –
                        conversational ai and workflow automation. Create
                        powerful chatbot with workflow automation in minutes
                        using no-code approach.
                      </p>
                      <RequestForm isFooterForm={false} />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 flex_home">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 features_change_img_header pl-0 pr-0">
                      <div className="features_change_img">
                        <div className="features_change_img-center">
                          <video loop autoPlay muted id="vid">
                            <source src={video} type="video/mp4" />
                          </video>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div class="bg_img_assistant_top"></div> */}
            </section>
            {isSmall ? (
              <section className="main-slider bg_slider_home features_header_home mobile_feat_video">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 flex_home">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 features_change_img_header pl-0 pr-0">
                        <div className="features_change_img">
                          <div className="features_change_img-center">
                            <video loop autoPlay muted id="vid">
                              <source src={video} type="video/mp4" />
                            </video>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ) : null}
            {featureContent.map((feature, index) => {
              if (index % 2 !== 0) {
                return (
                  <RightImageWithContentFeature
                    image={feature.image}
                    altImage={feature.alt}
                  >
                    <RightImageWithContentFeature.Header>
                      <div className="icon-top-img">
                        <img src={feature.icon} alt="icon" />{" "}
                        <span>{feature.title}</span>
                      </div>
                      <h3>{feature.header}</h3>
                    </RightImageWithContentFeature.Header>

                    <RightImageWithContentFeature.SubHeader>
                      <p>{feature.content}</p>
                    </RightImageWithContentFeature.SubHeader>
                    <RightImageWithContentFeature.Link>
                      <div className="card_link_landing">
                        <a href={feature.link} className="url_manipulation">
                          Learn more{" "}
                          <span className="arrow_svg_link">
                            <ArrowRightICon />
                          </span>
                        </a>
                      </div>
                    </RightImageWithContentFeature.Link>
                  </RightImageWithContentFeature>
                );
              }
              return (
                <LeftImageWithContent
                  image={feature.image}
                  altImage={feature.alt}
                >
                  <LeftImageWithContent.HeaderIcon>
                    <div className="icon-top-img">
                      <img src={feature.icon} alt="icon" />{" "}
                      <span>{feature.title}</span>
                    </div>
                  </LeftImageWithContent.HeaderIcon>

                  <LeftImageWithContent.MainHeader>
                    {feature.header}
                  </LeftImageWithContent.MainHeader>
                  <LeftImageWithContent.SubHeader>
                    <p>{feature.content}</p>
                  </LeftImageWithContent.SubHeader>
                  <LeftImageWithContent.Link>
                    <div className="card_link_landing">
                      <a href={feature.link} className="url_manipulation">
                        Learn more{" "}
                        <span className="arrow_svg_link">
                          <ArrowRightICon />
                        </span>
                      </a>
                    </div>
                  </LeftImageWithContent.Link>
                </LeftImageWithContent>
              );
            })}
            <KeyResources cards={resoucesData} />
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </div>
    </>
  );
}

export const cardData = [
  {
    image: cardOne,
    header: "Workativ for Conversational IT Support",
    content: `Deliver faster and superior IT Support, powered by Conversational AI and Automation. Augment your IT Help Desk to scale your support easily in a few clicks. `,
    content_1: `No coding required.`,
    linkAddress: "/assistant/it-helpdesk-chatbot",
    backgroundClass: "bg_card_1",
  },
  {
    image: cardTwo,
    header: "Workativ for Conversational HR Support",
    content: `Transform HR experience for employees with our AI-powered chatbots with prebuilt HR process automation. Free up your HR staff to focus on high value work.`,
    content_1: ` No coding required.`,
    linkAddress: "/conversational-ai-platform/conversational-hr-support",
    backgroundClass: "bg_card_2",
  },
];
